.hardware-card {
    max-width: 300px;
  }
  
  .hardware-card .metric h6 {
    margin-bottom: 0.25rem;
  }
  
  .hardware-card .progress {
    height: 10px;
  }
  
  .hardware-card .progress-bar {
    transition: width 0.3s ease;
  }
  