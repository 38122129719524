.authorBg {
    background: rgba(128, 128, 128, 0.352);
    /* fallback for old browsers */
    background: -webkit-linear-gradient(to bottom, rgba(128, 128, 128, 0.352),#240b36 );
    /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to bottom, rgba(128, 128, 128, 0.352), #240b36);
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.authorBgLayer {
    background: rgba(37, 37, 37, 0.352);
    /* fallback for old browsers */
    /* background: -webkit-linear-gradient(to bottom, #c3143171,#240b3670 );
    /* Chrome 10-25, Safari 5.1-6 */
    /* background: linear-gradient(to bottom, #c314315d, #240b365d); */
    /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

}
.selected{
    background-color: rgba(56, 54, 54, 0.74);
    color: white;
}
.mainCard1{
    background-color: rgb(89, 0, 166);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mainCard2{
    background-color: rgb(163, 163, 0);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.mainCard3{
    background-color: rgb(0, 138, 57);
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
